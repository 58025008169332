#logo {
  position: fixed;
  top: 50px;
  left: 3rem;
  z-index: 100;

  @media (max-width: 640px) {
    top: 2rem;
    left: 30px;
  }

  img {
    width: clamp(50px, 80px, 80px);
  }
}
.overlay-nav {
  // display: none;
  position: fixed;
  top: 50px;
  // left: 80vw;
  right: 3rem;
  box-sizing: border-box;
  z-index: 10;

  .header-container {
    display: flex;

    @media (max-width: 640px) {
      position: fixed;
      top: 2em;
      right: 30px;
    }

    h1 {
      cursor: pointer;
      transition: opacity 0.35s ease-in-out;
      text-transform: uppercase;
      mix-blend-mode: difference;

      // &:hover {
      //   color: rgb(252, 206, 122);
      // }
    }
  }
}

// @media (min-width: 768px) {
//   .overlay-burger-menu,
//   .overlay-burger-icon {
//     display: none;
//   }

//   .overlay-nav {
//     display: block;
//   }
// }

.overlay-burger-menu {
  position: fixed;
  z-index: 3;
  width: 100vw;
  height: 100vh;
  background: #fff;
  clip-path: inset(0% 0 100% 0);
  display: flex;
  flex-direction: column;
  padding-left: 30vw;
  padding-top: 30vh;
  transition: clip-path 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: #000;

  &.as-opened {
    clip-path: inset(0% 0 0% 0);
  }

  h1 {
    font: {
      size: 10vw;
      family: 'Lato';
    }
    text-transform: uppercase;
    margin: 20px 0;
  }

  .burger-menu-header {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
  }
}

.overlay-burger-icon {
  position: fixed;
  left: 50px;
  top: 50px;
  z-index: 5;
  cursor: pointer;
  width: 48px;
  height: 48px;
  border: none;
  background: 0 0;
  will-change: transform;
  transition: transform 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);

  &::after,
  &::before {
    content: '';
    width: 48px;
    height: 3px;
    position: absolute;
    background-color: #fff;
    left: 50%;
    top: 50%;
    display: block;
    will-change: transform;
    transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &::before {
    transform: translate(-50%, -10px) rotate(180deg);
  }

  &::after {
    transform: translate(-50%, 0px) rotate(0);
  }

  &.as-opened {
    &::after {
      background-color: #000;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::before {
      background-color: #000;
      transform: translate(-50%, -50%) rotate(315deg);
    }
  }
}

.select-language-area {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
  cursor: pointer;

  img {
    width: 15px;
    height: 15px;
    object-fit: cover;
    margin: auto 5px auto;
  }
}

.language-box {
  max-height: 0;
  position: absolute;
  right: -10px;
  top: 40px;
  background: #66cdaa;
  border-radius: 5px;

  overflow: hidden;
  transition: max-height 0.5s ease-in-out;

  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    color: #000;
    padding: 10px;

    li {
      cursor: pointer;
    }
  }
}
