.project-item-container {
  cursor: pointer;
  line-height: 1.25;
  padding: 3vh 0;

  @media (max-width: 768px) {
    padding: 5vh 0;
  }

  .project-title-mobile {
    position: relative;
    opacity: 0;
    margin-left: 5vw;
    margin-bottom: 10px;
    transition: opacity 1s ease;

    span {
      font-size: 0.85rem;
      margin-left: 10px;
      text-transform: uppercase;
      padding: 1px 5px;
      border: 1px solid #fff;
    }
  }

  .title-item {
    position: relative;
  }

  @media (max-width: 768px) {
    .title-item {
      margin-left: 5vw;
    }

    &.show {
      .title-item {
        z-index: 2 !important;

        .menu-title {
          clip-path: inset(0 0 0 0);
        }
      }

      .project-title-mobile {
        opacity: 1;
        margin-left: 5vw;
        margin-bottom: 10px;
        z-index: 2 !important;
      }

      img {
        left: 50%;
        top: 50%;
        opacity: 0.65 !important;
        transform: translate3d(-50%, -50%, 0) scale(1) !important;
        z-index: 1;
      }
    }
  }

  img {
    position: fixed;
    left: 33vw;
    top: 30vh;
    width: auto;
    height: clamp(250px, 27vw, 27vw);
    object-fit: cover;
    pointer-events: none;
    transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);

    @media (max-width: 768px) {
      left: 50%;
      top: 50%;
      height: 70vw;
      transform: translate3d(-50%, -50%, 0) scale(0.8) !important;
    }
  }

  .menu-title {
    font-size: 8.5vw;
    text-transform: uppercase;
    z-index: 1;
    line-height: 1.1;
    white-space: pre-line;

    @media (max-width: 768px) {
      font-size: 2.5rem;
    }

    &.clone {
      position: absolute;
      top: 0;
      pointer-events: none;
      color: #fff;
      clip-path: inset(0 100% 0 0);
      transition: all 0.7s cubic-bezier(1, 0, 0, 1);
    }

    &:not(.clone) {
      -webkit-text-stroke: 1px rgba(255, 255, 255, 0.5);
      color: transparent;

      @media (max-width: 768px) {
        -webkit-text-stroke-width: 1rem;
        -webkit-text-stroke: 1px rgba(255, 255, 255, 0.3);
      }

      &:hover {
        cursor: pointer;

        @media (min-width: 768px) {
          ~ .clone {
            clip-path: inset(0 0 0 0);
          }
        }
      }
    }
  }

  .info-block {
    position: fixed;
    top: 60%;
    transform: translateY(-50%);
    left: 80vw;
    z-index: 1;
    text-align: left;
    font-size: 1rem;

    .info-block-header {
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 22px;
      color: #fff;

      .hash {
        margin-right: 4px;
      }

      span {
        font-family: 'Lato';
        display: flex;
        align-items: center;
      }
    }

    p {
      margin-bottom: 10px;
      overflow: hidden;
      color: #999;

      span {
        display: block;
        opacity: 0;
        transition: all 0.25s ease-in-out;
        transform: translateY(10px);
      }
    }

    &.as-active {
      @for $i from 0 through 4 {
        p:nth-child(#{$i + 1}n) {
          span {
            transition-delay: #{$i/10}s;
          }
        }
      }
      span {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}

@media (max-width: 768px) {
  .info-block {
    display: none;
  }
}
