.next-project-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  background: #66cdaa;
  min-height: 30vh;

  @media (max-width: 640px) {
    min-height: 20vh;
  }

  a {
    padding: clamp(20px, 3vw, 3vw);
    width: 100%;
    color: #010113;
    text-align: right;

    .next-project-title-wrapper {
      white-space: pre-line;

      p {
        margin-bottom: 1rem;
      }

      h3 {
        font-size: clamp(2rem, 2.5vw, 2.5vw);
        line-height: 1.2;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
}

.link-icon-area {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 3rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .link-icon {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-right: 2rem;

    @media (max-width: 768px) {
      margin-bottom: 1rem;
    }

    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
}
