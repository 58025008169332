.footer-copyright {
  font-size: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100px;
  margin-left: 3rem;

  @media (max-width: 640px) {
    height: 80px;
    margin-left: 2rem;
  }
}

.footer-fixed-copyright {
  position: fixed;
  bottom: 1.5rem;
  left: 3rem;

  @media (max-width: 640px) {
    bottom: 2em;
    left: 30px;
  }
}
