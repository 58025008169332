.works-wrapper {
  background: #010113;
}

.no-bg {
  background-color: transparent !important;
}

.desktop-video-wrapper {
  padding: 1rem;
  background-color: #131321;
}

.col {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  background-color: #131321;

  img {
    width: 50%;
    padding: 1rem;
  }

  @media (max-width: 640px) {
    flex-direction: column;

    img {
      width: 100%;
    }
  }
}
