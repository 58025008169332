.scroll-info {
  display: block;
  position: fixed;
  left: 80vw;
  bottom: 2em;
  letter-spacing: 2px;
  z-index: 1;

  span {
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.7);
  }
}
