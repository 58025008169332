.content-wrapper {
  position: relative;
  width: 100%;
  height: auto;
  white-space: pre-line;
  color: #fff;
  background-color: #010113;
  padding: 10rem 10vw 0;

  @media (max-width: 1280px) {
    padding: 7rem 5vw 0;
  }

  .info-em-area {
    position: absolute;
    top: 20vh;
    left: 60%;
    transform: translateX(-20%);
    z-index: 2;

    h2 {
      text-align: right;
      font-size: clamp(7.9vw, 10vw, 10vw);
      line-height: 1;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.7);
      mix-blend-mode: difference;

      @media (max-width: 768px) {
        font-size: 11vw;
      }
    }
  }

  .info-wrapper {
    padding: 3rem 0;
    margin-bottom: 3rem;
    z-index: 1;

    @media (max-width: 640px) {
      flex-direction: column;
      margin-bottom: 0;
    }

    .info-image-area {
      position: relative;
      margin-top: 10vh;
      margin-left: 2.5rem;
      width: 25vw;
      height: auto;

      @media (max-width: 640px) {
        position: relative;
        width: 50vw;
        min-height: 27vw;
        margin-bottom: 3rem;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin: 0 auto;
        filter: brightness(0.5);
      }
    }

    .info-text-area {
      width: 30%;
      word-wrap: break-word;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      color: #fff;

      @media (max-width: 640px) {
        width: 80%;
      }

      .info-name-paragraph {
        font-size: 1.25rem;
        font-weight: bold;
        margin-bottom: 2.5rem;
      }

      p {
        flex: 1;
        text-align: left;
        font-size: 1.15rem;
        line-height: 2;

        @media (max-width: 640px) {
          text-align: center;
          font-size: 1rem;
        }
      }
    }
  }

  .overlay {
    display: block;
    width: 100%;
    height: auto;
    background-color: #010113;
    z-index: 1;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10%/5%;
    }

    video {
      width: 100%;
      height: 100%;
    }
  }

  .desktop-wrapper {
    width: 100%;
    margin: 0 auto;
    background-color: #131321;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .mobile-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #131321;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }

    .device {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }

    .flex-col {
      position: relative;
      width: 50%;
      padding: clamp(1em, 7vh, 10em);

      @media (max-width: 768px) {
        padding: 10px;
      }
    }

    img.overlay,
    video.overlay {
      -o-object-fit: cover;
      object-fit: cover;
    }

    .single-image {
      width: 90.2%;
      background: rgba(100, 100, 100, 0.1);
      overflow: hidden;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      border-radius: 10%/5%;

      &::before {
        content: '';
        display: block;
        padding-top: 216%;
      }
    }

    .overlay-device-image {
      width: 100%;
      position: relative;

      &::before {
        content: '';
        display: block;
        padding-top: 202%;
      }
    }

    .overlay-device {
      background-size: cover !important;
    }
  }

  .info-wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;

    &::before {
      content: '';
      display: block;
      width: 1px;
      height: 100%;
      background-color: #010113;
    }

    p {
      font-weight: normal;
      text-transform: uppercase;
      padding: 0;
      margin: 0;
    }
  }

  .title-area {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 0 20px;
    z-index: 2;

    h3 {
      flex: 2;
      font-family: 'Poppins';
      font-size: 5.7vw;
      font-size: clamp(48px, 7vw, 7vw);
      //   letter-spacing: -0.25rem;
      text-transform: uppercase;
      line-height: 5.7vw;
      line-height: 1;
      margin: 0;

      @media (max-width: 640px) {
        font-size: clamp(48px, 5.1vw, 90px);
        line-height: 1;
        letter-spacing: 0;
      }
    }
  }

  .detail-content-wrapper {
    display: flex;
    flex-direction: column;
    line-height: 1.65rem;
    margin: 5rem auto;

    h3 {
      font-size: 1.25rem;
      font-weight: bold;
      margin-bottom: 1.25rem;
    }

    p {
      line-height: 2;
    }

    .col-2:nth-child(1) {
      padding-right: 1.5rem;
    }

    .col-2:nth-child(2) {
      padding-left: 1.5rem;
    }
  }

  .row-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 2.5rem;
    margin-bottom: 5rem;

    @media (max-width: 768px) {
      flex-direction: column;
      margin-top: 0;
      margin-bottom: 3rem;
    }
  }

  .info-box {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 1rem 1rem;

    small {
      font-size: 0.5rem;
      color: #555;
      text-transform: uppercase;
    }

    p {
      margin: 0;
    }

    @media (max-width: 640px) {
      width: 100%;
      margin: 0 auto 5vw;
    }
  }

  .divider {
    display: block;
    height: 1px;
    width: 100%;
    background-color: #131321;
    margin: 0.5rem 0;
  }

  .content-section {
    flex: 1;
    padding: 0 2rem;

    @media (max-width: 768px) {
      margin-bottom: 7vh;
      padding: 0;
    }

    h3 {
      font-size: 1rem;
      font-family: 'Poppins';
      font-weight: 600;
      text-transform: uppercase;
      margin: 0;
      margin-bottom: 5px;
    }

    p {
      padding: 0;
      margin: 0;
    }

    a {
      color: #010113;
      text-decoration: none;
    }
  }

  .main-image-box {
    flex: 2;
    margin-right: 30px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media (max-width: 640px) {
      width: 100%;
    }
  }

  .content {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    white-space: pre-line;
    line-height: 2rem;
    z-index: 2;

    @media (max-width: 640px) {
      flex-direction: column;
    }
  }
}

.step-area {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10rem 10rem;
  background-color: #131321;

  .step {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h4 {
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }
  }
}

.info-detail-wrapper {
  width: 100%;
  // margin: 0 auto 15vw;
  padding: 5rem 15vw;
  flex-direction: column;
  justify-content: flex-end;
  background: #66cdaa;
  background: #f3f3f3;

  @media (max-width: 768px) {
    padding: 5rem 10vw;
  }

  .info-detail-inner {
    width: 75vw;
    display: flex;
    flex-direction: row;
    color: #131321;
    line-height: 1;
    gap: 5vw;

    &:not(:last-child) {
      margin-bottom: 10vh;
    }

    .title-area {
      flex: 1;

      h3 {
        font-family: 'Bebas neue';
        font-size: 2rem;
        margin: 1vw;
      }
    }

    .content-area {
      flex: 3;
      display: flex;
      text-align: left;

      ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;

        li {
          margin: 1vw;
        }
      }
    }
  }
}

.contact-wrapper {
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  div {
    flex: 1;
    text-transform: uppercase;

    p {
      font-size: 1.5rem;
      line-height: 1;
      padding: 1rem;

      @media (max-width: 768px) {
        padding: 0;
        margin-bottom: 2.5rem;
      }
    }

    a {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-size: 1.5rem;
      padding: 1rem;

      @media (max-width: 768px) {
        padding: 0;
        font-size: 1rem;
      }

      img {
        width: 1.5rem;
        object-fit: contain;
        margin-right: 10px;

        @media (max-width: 768px) {
          width: 1rem;
        }
      }

      @media (max-width: 768px) {
        padding: 1rem 0;
      }
    }
  }
}
