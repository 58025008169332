.parallax {
  overflow: hidden;
  letter-spacing: -2px;
  line-height: 0.8;
  margin: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  // transform: rotate(90deg);
}

.parallax .scroller {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 64px;
  display: flex;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
}

.parallax span {
  // font-size: clamp(2rem, 3rem, 5rem);
  font-size: 13vw;
  display: block;
  margin-right: 30px;
  color: #212336;
  color: rgba(255, 255, 255, 0.35);

  @media (max-width: 640px) {
    font-size: 8.7vh;
  }
}
