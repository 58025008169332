.secondary-cursor {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  border-radius: 50%;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  position: fixed;
  background: transparent;
  border: 3px solid #66cdaa;
  transition: width 0.5s cubic-bezier(0.19, 1, 0.22, 1),
    height 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  transform-origin: center;
  pointer-events: none;

  &.regular {
    width: 12rem;
    height: 12rem;
  }

  &.medium {
    width: 7rem;
    height: 7rem;
  }

  &.small {
    width: 1.5rem;
    height: 1.5rem;
  }

  &.next {
    width: 8rem;
    height: 8rem;
    background: #010113;
    border: none;
    transition: width 1s cubic-bezier(0.19, 1, 0.22, 1),
      height 1s cubic-bezier(0.19, 1, 0.22, 1);

    p {
      color: #fff;
      text-transform: uppercase;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
}
