#text-circle title {
  fill: white;
  color: white;
}
#text-circle text {
  fill: #66cdaa;
  font-family: 'Lato', sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: uppercase;
  font-variant-ligatures: none;
  letter-spacing: 1px;
  animation: rotate 25s linear infinite;
  transform-origin: 250px 250px;

  @media (max-width: 640px) {
    font-size: 2rem;
  }
}

#text-circle {
  position: absolute;
  top: 0%;
  left: 0%;
  max-width: 30vmin;
  transform: translate(-50%, -50%);
  z-index: 2;

  @media (max-width: 640px) {
    max-width: 35vmin;
  }
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

#text-circle .textcircle {
  transition: transform 1s cubic-bezier(0.65, 0, 0.35, 1);
  transform-origin: 250px 250px;
}

#text-circle:hover .textcircle {
  transform: scale(1.2) rotate(90deg);
}
// #text-circle:hover .textcircle {
//   transform: scale(0.5) rotate(-90deg);
// }
